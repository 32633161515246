import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import Recaptcha from 'react-google-invisible-recaptcha';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import DOMPurify from 'dompurify';
import Helmet from "react-helmet"

const axios = require('axios');

const APOLLO_QUERY_CONTACT = gql`
  {
  	  contacts {
	    nodes {
	      content
	    }
	  }

  }
`

let content = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;

const sanitizer = DOMPurify.sanitize;

const IndexPage = () => {

	useEffect(() => {
		
		// document.body.style.backgroundColor = "rgba(255, 165, 0, 0.1)";

	})

	const [nameValue, setNameValue] = useState('Name*');
	const [emailValue, setEmailValue] = useState('Email*');
	const [phoneValue, setPhoneValue] = useState('Phone');
	const [messageValue, setMessageValue] = useState('Message*');
	const [submittedCheckFailedStatus, setSubmittedCheckFailedStatus] = useState(''); // check whether form submisson check failed
	const [confirmationValue, setConfirmationValue] = useState('');
	const recaptcha = useRef(null);

	const checkField = (event) => {

		if(event.target.value==='Name*'){
			setNameValue('');
		}

		if(event.target.value==='Email*'){
			setEmailValue('');
		}

		if(event.target.value==='Phone'){
			setPhoneValue('');
		}
		
		if(event.target.value==='Message*'){
			setMessageValue('');
		}
	}

	const updateName = (event) => {

		setNameValue(event.target.value);
	}

	const updateEmail = (event) => {

		setEmailValue(event.target.value);
	}

	const updatePhone = (event) => {

		setPhoneValue(event.target.value);
	}

	const updateMessage = (event) => {

		setMessageValue(event.target.value);
	}

	const checkEmail = () => {

		const email = emailValue;
		var atpos=email.indexOf("@");
	    var dotpos=email.lastIndexOf(".");
	    if (atpos < 1 || dotpos < atpos+2 || dotpos+2 >= email.length) {
	       
	        return false;
	    }
	    else{

	    	return true;
	    }
	}

	const verifyEmail = (event) => {

		if(!checkEmail()){

			setSubmittedCheckFailedStatus("Please enter a valid email address");

		}
		else{

			setSubmittedCheckFailedStatus('');
		}

	}

	const handleFileSubmit = async  (e) => {

		const token = await recaptcha.current.execute();

		if(token){

			axios.post('https://the-sharpener-d5tt.temp-dns.com/db/recaptcha.php',
		  	{ 
		  	
			  secret: process.env.GATSBY_CAPTCHA_SECRETKEY,
              response: token,
			
			
			}
		  )
		  .then(response => {
		    
		  	if(response.data>0.5){

		  		handleFileSubmitComplete();
		  	}
		  	else{

		  		setSubmittedCheckFailedStatus("Google Recaptcha failed. Please try again")
		  	}


		  })
		  .catch(error => {
		    console.log(error);
		  });

		}

	}

	const handleFileSubmitApproved = (event) => {

	}

	const handleFileSubmitComplete = () => {

		if(nameValue!="Name*" && nameValue!="" && nameValue!="Email*" && emailValue!="" && messageValue!="Message*" && messageValue!="" ){

             axios.post("https://the-sharpener-d5tt.temp-dns.com/db/mail.php",  {
               
               name: nameValue,
               email: emailValue,
               phone: phoneValue,
               message: messageValue
            })
            .then(function (response) {
                
                setConfirmationValue('Thank you. Your message has been sent.')
                setSubmittedCheckFailedStatus('');


            })
              .catch(function (error) {
                // console.log('-------------')
                console.log(error);
                // setConfirmationValue('There was a problem submitting your form. Please try again')
                // setSubmittedCheckFailedStatus('There was a problem submitting your form. Please try again')
            });

        }
        else{

        	setSubmittedCheckFailedStatus('Please complete the required fields')
        }

		


	}


	return (
	  <Layout>
	    <SEO title="Contact" />

	    <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Experienced copywriter in Buckinghamshire</title>
            <meta name="title" content="Experienced copywriter in Buckinghamshire" />
            <meta name="description" content=" Need the help of an experienced copywriter to transform your copy and write content that reaches your audience? Give me a call." />
        </Helmet>

	    <Grid container className="main">

	    	<Grid container className="contactpage">
	    		<h1>Let's talk</h1>

	    		<Grid container className="contact">
	    		
		    		<Grid item className="contact_text">

			    		{/*<p>If you're interested in a freelance writer to help transform your copy and write sparkling content that counts, then I'd love to hear from you. I'm based in Bucks although I'm happy to travel if needed.</p>

 						<p>You can contact me via the form below, or call me on 07717 797 259.</p>*/}

 						 <Query query={APOLLO_QUERY_CONTACT}>

		                      {({data}) => {

		                      if(data){

		                      	content = <div dangerouslySetInnerHTML={{ __html: sanitizer(data.contacts.nodes[0].content)}} />
		                      	
		                      }

		                      return content

		                    }}

		                </Query>

					</Grid>

				</Grid>

				<Grid container className="contact_form">

					{submittedCheckFailedStatus && 

						<Grid item className="input_error">{submittedCheckFailedStatus}</Grid>

					}


					{!confirmationValue &&
					
					<React.Fragment>
					
						<Grid item className="name"><input onClick={checkField} onChange={updateName} name="name" className="name_field" type="text" value={nameValue}/></Grid>
						
						<Grid item className="email"><input onClick={checkField} onChange={updateEmail} onBlur={verifyEmail} name="email" className="email_field" type="text" value={emailValue} /></Grid>

						<Grid item className="phone"><input type="text" onClick={checkField} onChange={updatePhone} name="phone" className="phone_field" value={phoneValue} /></Grid>

						<Grid item className="message"><textarea type="text" onClick={checkField} onChange={updateMessage} name="message" className="message_field" value={messageValue} /></Grid>

						<Grid item className="submit"><button  onClick={ handleFileSubmit } className="submit_field">Submit</button></Grid>


						<Recaptcha
				            ref={recaptcha}
				            sitekey={process.env.GATSBY_CAPTCHA_SITEKEY}
				            onResolved={handleFileSubmitApproved}
				        />


					</React.Fragment>
					}

					{confirmationValue &&

						<Grid item className="form_confirmation">{confirmationValue}</Grid>

					}

				

				</Grid>



	    	</Grid>



	    </Grid>
	    
	  </Layout>
	)

}

export default IndexPage
